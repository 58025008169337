<template>
  <ion-page id="rolechats-page" class="page bg-transparent">
    <div class="m-2 d-flex justify-content-between align-items-center">
      <div>
        <router-link :to="{ name: 'rolechats' }" class="text-black">
          <i class="ti-angle-left back-btn clickable-item-hov bold" />
        </router-link>
      </div>
      <div>
        <div v-if="!isTitleEdit" class="d-flex align-items-center">
          <h1 class="mr-2 my-1 text-center">
            <strong>{{ roomTitle }}</strong>
          </h1>
          <a href="#" class="clickable-item-hov" @click.prevent="onRoomTitleEdit">Edit</a>
        </div>
        <div v-else class="d-flex align-items-center mb-3">
          <ion-input v-model="roomTitleNew" class="c-input mr-2 title-input" placeholder="Room Title" maxlength="20" />
          <ion-button
            class="inline-button text-white mr-2"
            :disabled="isTitleEditSaving"
            @click.prevent="onRolechatTitleUpdate"
          >
            <i class="ti-check" />
          </ion-button>

          <ion-button
            class="inline-button text-white"
            :disabled="isTitleEditSaving"
            color="danger"
            @click.prevent="() => (isTitleEdit = false)"
          >
            <i class="ti-close" />
          </ion-button>
        </div>
      </div>
      <div></div>
    </div>
    <div class="messages-content px-2" ref="messagesContainer" @scroll="handleScroll">
      <div v-if="isLoading || isRoomLoading" class="d-flex justify-content-center my-4">
        <ChLoading size="lg" class="spinner" />
      </div>
      <div v-if="chatMessages && chatMessages.length && !isRoomLoading">
        <p v-if="nextPageExists && !isLoading" class="clickable-item-hov text-center" @click="requestLoadMore">
          Load More
        </p>

        <div v-for="message of chatMessages" :key="message.id">
          <div :id="`message-${message.id}`">
            <chat-msg-item v-if="message.character" :message="message"></chat-msg-item>
            <chat-narr-msg-item v-else :message="message"></chat-narr-msg-item>
          </div>
        </div>
      </div>
      <div v-else class="my-4">
        <div class="mb-2">No Messages Yet.</div>
        <div>Be the one to start the conversation!</div>
      </div>
    </div>
    <div class="mt-2 mx-2 position-relative">
      <ion-button 
        size="large"
        v-if="isScrollLocked && newMessageWaiting" 
        class="jump-btn text-center"
        color="secondary"
        @click="jumpToBottom"
      >
        <i class="ti-angle-down clickable-item-hov bold" />
      </ion-button>
      <post-chat-message></post-chat-message>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import ChatMsgItem from './ChatMsgItem.vue';
import ChatNarrMsgItem from './ChatNarrMsgItem.vue';
import PostChatMessage from './PostChatMessage.vue';
import { getRolechatRoom, getRolechatRoomMsgs, markRoomMsgsRead, updateRolechatRoom } from '@/shared/actions/rolechats';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import store from '@/shared/store';

const messagesContainer: Ref = ref(null);
const isLoading = ref(true);
const isRoomLoading = ref(true);
const isTitleEdit = ref(false);
const isTitleEditSaving = ref(false);
const roomTitle = ref('');
const roomTitleNew = ref('');
const chatMessages: any = ref([]);
const roleChatPage = ref(1);
const isScrollLocked = ref(false);
const newMessageWaiting = ref(false);
const wsConnection = ref<WebSocket | null>(null);
const rolechatPaging = ref({
  next: null,
  previous: null,
  count: 0,
  page: 1,
}) as Ref<{ next: string | null; previous: string | null; count: number; page: number }>;

const emits = defineEmits(['changeTab']);
const { user, authToken } = authStore();
const router = useRouter();

const getUserCharacters = (args: any) => store.dispatch('CharactersModule/getUserCharacters', args);
const getUnreadRolechatStatus = () => store.dispatch('RolechatModule/getUnreadRolechatStatus');
const nextPageExists = computed(() => {
  return !!rolechatPaging.value.next;
});

const fetchMessages = async (page = 1, pageSize = 50) => {
  isLoading.value = true;

  let latestMsg: {} | undefined;
  try {
    const { results, ...paging } = await getRolechatRoomMsgs(
      String(router.currentRoute.value.params.id),
      page,
      pageSize
    );

    const reverseResults = orderBy(results, ['created'], ['asc']);

    latestMsg = last(reverseResults);

    chatMessages.value = [...reverseResults, ...chatMessages.value];
    rolechatPaging.value = paging;
    roleChatPage.value = page;
  } catch (_err) {}
  isLoading.value = false;

  if (roleChatPage.value === 1 && latestMsg) {
    nextTick(() => {
      const document = useDocument();
      document.value
        ?.getElementById(`message-${get(latestMsg, 'id')}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    });
    await markRoomMsgsRead(String(router.currentRoute.value.params.id));
    getUnreadRolechatStatus();
  }
};

const fetchRolechatRoom = async () => {
  isRoomLoading.value = true;

  try {
    const resp = await getRolechatRoom(String(router.currentRoute.value.params.id));
    roomTitle.value = roomTitleNew.value = resp.title;
  } catch (_err) {
    toast.show("Couldn't find the requested roleplay chat.", 'nonative', 'danger');
    router.push({ name: 'rolechats' });
  }

  isRoomLoading.value = false;
};

const requestLoadMore = async (ev: MouseEvent) => {
  if (!rolechatPaging.value.next) {
    (ev?.target as any).complete();
  } else {
    await fetchMessages(roleChatPage.value + 1);
  }
};

const onRoomTitleEdit = () => {
  roomTitleNew.value = roomTitle.value;

  isTitleEdit.value = true;
};

const onRolechatTitleUpdate = async () => {
  if (!roomTitleNew.value) {
    toast.show('Enter a room title first.', 'nonative', 'danger');
    return;
  }

  isTitleEditSaving.value = true;

  try {
    await updateRolechatRoom(String(router.currentRoute.value.params.id), { title: roomTitleNew.value });
    roomTitle.value = roomTitleNew.value;
  } catch (_err) {
    toast.show('Cannot update roleplay chat title. Please try again.', 'nonative', 'danger');
  }

  isTitleEditSaving.value = false;
  isTitleEdit.value = false;
};

const goToDiscover = () => {
  emits('changeTab', { value: 'discover' });
};

const hookRolechatRoomWS = () => {
  const {
    public: { wsUrl },
  } = useRuntimeConfig();

  const { wsConnection } = useSocket(
    `${wsUrl}/ws/rolechats/room/${router.currentRoute.value.params.id}/?token=${authToken.value}`
  );

  wsConnection.value.onmessage = (event) => {
    const data = JSON.parse(event.data).message;

    if (data.action === 'create') {
      chatMessages.value.push(data.message);
      if (isScrollLocked.value) {
        newMessageWaiting.value = true;
      } else {
        nextTick(() => {
          document
            .getElementById(`message-${get(data.message, 'id')}`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        });
      }
    }
  };
};

const handleScroll = () => {
  const scrollElement = messagesContainer.value;

  if (scrollElement) {
    const scrollHeight = scrollElement.scrollHeight;
    const scrollTop = scrollElement.scrollTop;
    const clientHeight = scrollElement.clientHeight;
    
    if (scrollTop + clientHeight < scrollHeight) {
      isScrollLocked.value = true;
    } else {
      isScrollLocked.value = false;
      newMessageWaiting.value = false;
    }
  }
};

const jumpToBottom = () => {
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
    newMessageWaiting.value = false;
    isScrollLocked.value = false;
  }
};

onMounted(async () => {
  getUserCharacters({ id: user.value.id, page: 1, includeUnlisted: true });
  await fetchRolechatRoom();
  fetchMessages();
  hookRolechatRoomWS();
});
</script>

<style scoped lang="sass">
.page
  padding: 0 !important
.unread-chat
  font-weight: bold
.messages-content
  position: relative
  height: calc(100vh - 315px)
  height: calc(100dvh - 315px)
  overflow: auto
.spinner
  width: 40px
  height: 40px
.back-btn
  width: 30px
  height: 30px
.title-input
  max-width: 350px
  @media(min-width: 500px)
    width: 350px
.jump-btn 
  padding: 0 !important
  position: absolute
  top: -60px
  right: 10px
  width: 100px
  color: white
  cursor: pointer
  z-index: 2
  &:hover
    opacity: 0.7
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
  @media (max-width: 570px)
    padding: 10px 15px
    font-size: 14px
    width: 75px
    &:hover
      opacity: 0.7
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2)
</style>
